<template>
    <div class='contactUs'>
        <div class="row">
            <div class="col">
                <p class="fs-2">
                    Curious About an Estimate?
                    <br> Have Questions?
                    <br>

                </p>
                <br>
                <h2>
                    Reach out to Us!
                </h2>
            </div>
        </div>
        <br>
        <br>
        <div class="row justify-content-around ">
            <img class="col-2" src="../assets/GemOnly.png" alt="">
            <div class="col-8 col-md-3 fs-4 ">
                <br>

                Contact Information:
                <br>
                <br>
                Phone: 208-789-4544
                <br>
                <br>
                Email: neil@gemstatetracks.com
            </div>
            <img class="col-2" src="../assets/GemOnly.png" alt="">
        </div>
    </div>
</template>

<!-- Include centered contact information. for styling due to lack of pictures, suggest a color scheme to prevent the page from just seeming to be a white blob with text.

Contact information should include the following:
email (if multiple for certain things, will need to specify. IE an email for estimates, and an email for questions and FAQ)
phone number
-->

<script>
export default {
    name: 'ContactUs',
}
</script>