<template>
  <div class="home">
    <div class="row justify-content-center blackBackground">
      <img class="col-12 trackHeader" alt="Track Header Image" src="../assets/trackHeaderImage.png">
    </div>
    <br>
    <div class="row ">
      <div class="col-12 col-md-8 pageHeader">
        <p class="text-center textBorders pageHeader fs-2">
          <br>Welcome to Gem State Tracks! <br>

        </p>
        <p class="text-start fs-5 pageBody">
          We specialize in Track and Field surface painting. We paint everything the general public has never noticed that
          goes into making a paved or rubberized oval a Competition Track and Field surface. Straight lines, beautiful
          curves, and accurately measured start lines, lane numbers, hurdle marks, exchange zone marks, and school
          name/mascot lettering. With over a decade of experience painting running tracks at middle schools, high schools,
          colleges, and universities all over the country, we are the company to turn to for all your Track and Field
          painting needs!
        </p>
      </div>

      <img class="col-12 col-md-4 pictureBorders" src="../assets/LowryRepaint.png" alt="">
    </div>
    <br>
    <div class="row">
      <div class="col-12 col-md-8 pageHeader">
        <p class="text-center textBorders pageHeader fs-2">
          <br> A little about us<br>

        </p>
        <p class="text-start fs-5 pageBody">
          We are a small, extremely mobile company based in Boise, Idaho. We most commonly work in California, Washington,
          Nevada, and, of course, Idaho. Other frequent locations we visit include Montana and Utah.
          <br>
          <br>
          Our team, although small, consists of those who have over a decade of experience in track painting and
          construction and take pride in their work. No matter how big a job might be, we are willing to tackle it and
          bring promisiing results!
        </p>
      </div>

      <img class="col-12 col-md-4 pictureBorders" src="../assets/UnionMineOverhead.png" alt="">
    </div>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',

}
</script>
